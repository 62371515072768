import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-right mt-n1 mb-3" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { for: "comment1" }
const _hoisted_6 = {
  key: 3,
  class: "btn btn-primary"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "text-right mt-n1 mb-3" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { for: "comment2" }
const _hoisted_12 = {
  key: 3,
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnershipCard = _resolveComponent("PartnershipCard")!
  const _component_LimitedTextArea = _resolveComponent("LimitedTextArea")!
  const _component_SimpleForm = _resolveComponent("SimpleForm")!
  const _component_ShortlistPeerReviewVisitorSelect = _resolveComponent("ShortlistPeerReviewVisitorSelect")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_TaskForm = _resolveComponent("TaskForm")!

  return (_openBlock(), _createBlock(_component_TaskForm, { onSubmit: _ctx.confirmChoices }, {
    default: _withCtx(() => [
      (!_ctx.isSubmitting)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_SimpleForm, {
              class: "box--bordered",
              target: `wp-json/api/send-invite/${_ctx.task?.reviewId}/1`,
              "success-text": "Uitnodiging #1 is verstuurd",
              onResponse: _ctx.onInviteSubmit
            }, {
              default: _withCtx(() => [
                _cache[5] || (_cache[5] = _createElementVNode("h5", null, "Partnerschap 1: Vrije Keuze", -1)),
                (_ctx.selectedFreePartnership)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createVNode(_component_PartnershipCard, {
                        partnership: _ctx.selectedFreePartnership.invitedPartnership,
                        "invite-status": _ctx.selectedFreePartnership.status
                      }, null, 8, ["partnership", "invite-status"]),
                      _createElementVNode("div", _hoisted_2, [
                        (_ctx.selectedFreePartnership.isRetractable)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 0,
                              type: "button",
                              class: "btn--trash",
                              onClick: _cache[0] || (_cache[0] = () => _ctx.clearChoice(_ctx.PartnershipSelectionType.FREE))
                            }, _cache[3] || (_cache[3] = [
                              _createElementVNode("i", { class: "oif oif-trash" }, null, -1),
                              _createTextVNode(" Verwijder keuze #1. ")
                            ])))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _cache[4] || (_cache[4] = _createElementVNode("p", null, "Je mag hier een partnerschap naar eigen keuze selecteren, zolang deze nog beschikbaar is.", -1)),
                      _createElementVNode("button", {
                        type: "button",
                        class: "btn btn-primary",
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args)))
                      }, " Kies partnerschap #1 ")
                    ])),
                (_ctx.selectedFreePartnership?.status === 'draft' && !_ctx.task?.isComplete)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("label", _hoisted_5, "Optioneel: Persoonlijk bericht voor " + _toDisplayString(_ctx.selectedFreePartnership.invitedPartnership.label) + ":", 1),
                      _createVNode(_component_LimitedTextArea, { name: "comment1" })
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.selectedFreePartnership?.status === 'draft')
                  ? (_openBlock(), _createElementBlock("button", _hoisted_6, " Verstuur uitnodiging #1 "))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["target", "onResponse"]),
            _createVNode(_component_SimpleForm, {
              class: "box--bordered",
              target: `wp-json/api/send-invite/${_ctx.task?.reviewId}/2`,
              "success-text": "Uitnodiging #2 is verstuurd",
              onResponse: _ctx.onInviteSubmit
            }, {
              default: _withCtx(() => [
                _cache[8] || (_cache[8] = _createElementVNode("h5", null, "Partnerschap 2: Shortlist", -1)),
                (_ctx.selectedShortlistPartnership)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_component_PartnershipCard, {
                        partnership: _ctx.selectedShortlistPartnership.invitedPartnership,
                        "invite-status": _ctx.selectedShortlistPartnership.status
                      }, null, 8, ["partnership", "invite-status"]),
                      _createElementVNode("div", _hoisted_8, [
                        (_ctx.selectedShortlistPartnership.isRetractable)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 0,
                              type: "button",
                              class: "btn--trash",
                              onClick: _cache[2] || (_cache[2] = () => _ctx.clearChoice(_ctx.PartnershipSelectionType.SHORTLIST))
                            }, _cache[6] || (_cache[6] = [
                              _createElementVNode("i", { class: "oif oif-trash" }, null, -1),
                              _createTextVNode(" Verwijder keuze #2. ")
                            ])))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _cache[7] || (_cache[7] = _createElementVNode("p", null, "Het tweede partnerschap mag je uit één van drie partnerschappen uit onderstaande lijst selecteren.", -1)),
                      _createVNode(_component_ShortlistPeerReviewVisitorSelect)
                    ])),
                (_ctx.selectedShortlistPartnership?.status === 'draft' && !_ctx.task?.isComplete)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("label", _hoisted_11, "Optioneel: Persoonlijk bericht voor " + _toDisplayString(_ctx.selectedShortlistPartnership.invitedPartnership.label) + ":", 1),
                      _createVNode(_component_LimitedTextArea, { name: "comment2" })
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.selectedShortlistPartnership?.status === 'draft')
                  ? (_openBlock(), _createElementBlock("button", _hoisted_12, " Verstuur uitnodiging #2 "))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["target", "onResponse"])
          ], 64))
        : (_openBlock(), _createBlock(_component_ProgressSpinner, { key: 1 }))
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}